import React from 'react';
import classnames from 'classnames';
import './button.css'

function Button(props) {
    let classes = classnames(
        "button", 
        {"button--primary": props.type===2},  
        {"button--secondary": props.type!==2}, 
        props.className 
        );
    return(
            <button className={classes} onClick={props.onClick}>
                <span className="button__text">{props.children}</span> 
            </button>
    )
}

export default Button;