import React from 'react'
import { FaPinterestP } from 'react-icons/fa';
import {
  FiInstagram, FiFacebook, FiMail, FiPhoneCall, FiMapPin
} from 'react-icons/fi'
import { OutboundLink } from "gatsby-plugin-google-gtag-optin"


const SocialIcons = ({ settings }) => {
  const { email, instagram, facebook, pinterest, phone } = settings;
  return (
    <div className="tar">
      <OutboundLink href={"tel: " + phone} target="_blank" rel="noreferrer" className="link-1">
        <FiPhoneCall className="icon" />
      </OutboundLink>
      <OutboundLink href={settings.googlemaplink} target="_blank" rel="noreferrer" className="link-1">
        <FiMapPin className="icon" />
      </OutboundLink>
      <OutboundLink href={"mailto: " + email} target="_blank" rel="noreferrer" className="link-1">
        <FiMail className="icon"></FiMail>
      </OutboundLink>
      <OutboundLink href={instagram} target="_blank" aria-label="instagram" rel="noreferrer" className="link-1"><FiInstagram className="icon"></FiInstagram></OutboundLink>
      <OutboundLink href={facebook} target="_blank" aria-label="facebook" rel="noreferrer" className="link-1"><FiFacebook className="icon"></FiFacebook></OutboundLink>
      <OutboundLink href={pinterest} target="_blank" aria-label="pinterest" rel="noreferrer" className="link-1"><FaPinterestP className="icon"></FaPinterestP></OutboundLink>
    </div>
  )
}

export default SocialIcons