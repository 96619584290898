
import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "./logo"
// import { useStaticQuery, graphql } from "gatsby"
// import MobileMenu from "./mobileMenu"
// import LanguageSwitcher from "./languageSwitcher"
import "./topMenu.css"
import InfoMenu from "./info-menu/info-menu"
import MobileMenu from "./mobileMenu"

function TopMenu (props){
  // console.log(props);
  const nav = props.translation.nav.filter(item=>item.inTopMenu);
  let [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    return  (<div className="top-menu">
      <InfoMenu settings={props.settings} mobileMenuVisible={mobileMenuVisible} setMobileMenuVisible={setMobileMenuVisible}  lang={props.lang || "fi"} languages={props.languages} slug={props.slug}></InfoMenu>
      <div className="top-menu-in">
        <div className="top-menu-links top-menu-links--desktop">
           
          <div className="top-menu-items tablet">

            {
                        nav.map(item => (
                        <Link key={item.title} to={item.url} className="top-menu-item link-1">
                            <span className="vam">{item.title}</span>
                        </Link>
                        ))
                    }
            
            {/* <LanguageSwitcher activeLanguage={props.lang || "fi"} languages={props.languages} slug={props.slug}></LanguageSwitcher> */}
            </div>
            <div className="logo df">
          <Link to={props.translation.nav[0].url}>
            <Logo></Logo>
            </Link>
        </div>
        </div>
       

    </div>
    <MobileMenu lang={props.lang} languages={props.languages} translation={props.translation} settings={props.settings} visible={mobileMenuVisible} onClose={()=>setMobileMenuVisible(false)}></MobileMenu>

    </div>);
}

 

export default TopMenu