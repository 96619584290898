import React from 'react'
import { Link } from 'gatsby'
import slugHandler from '../api/slugHandlerBrowser'
import { MdArrowDropDown } from 'react-icons/md'
import {IoIosGlobe} from 'react-icons/io';
import "./languageSwitcher.css"
import { saveUserLanguage } from '../api/userLanguage';

const LanguageSwitcher = ({ activeLanguage, languages, slug, color, className }) => {

  return (
    <div className={"langSwitch " + (className || "")}>
        <IoIosGlobe className="icon"/>
        <span className="vam">{ activeLanguage }</span>
        <MdArrowDropDown className="icon-md"/>

        <div className="langSwitch-menu">
      {
      languages.map(lang => {
          const url = slugHandler(
            lang,
            slug
          );
          return (
                <Link
                key={lang}
                className={`langSwitch-item ${activeLanguage === lang ? 'langSwitch-item--active' : ''}`}
                to={url}
                onClick={()=>saveUserLanguage(lang)}
                >
                {lang}&nbsp;
                </Link>
            )
            }
      )}

        </div>
    </div>
  )
}

export default LanguageSwitcher