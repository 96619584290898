import React from 'react'
import { RiCloseLine, } from 'react-icons/ri'
import { Link } from "gatsby"
import Logo from "./logo"
import LanguageSwitcher from './languageSwitcher'
import SocialIcons from './socialIcons'
import "./mobileMenu.css"
import "./topMenu.css"

function MobileMenu(props) {
    let classes = "mobile-menu page__in color-text" + (props.visible ? "" : " dn");
    const nav = props.translation.nav;
    return (
        <div className={classes}>
            <div className="mobile-menu-out">
                <div className="mobile-menu-item-image mobile-menu-item-image--default"
                style={{backgroundImage: "url('"+ nav[0].image +"')"}}
                />
                
                <div className="top-menu-in container-1200 mb2 posr">
                    <Link to={nav[0].url}><Logo></Logo></Link>
                    <div className="">
                    <button className="mobile-menu-item" onClick={props.onClose}>
                        <RiCloseLine className="icon-md"></RiCloseLine>
                    </button>

                    </div>

                </div>
                
                <div className="container-1200">
                    <div className="mobile-menu-in tal">
                        <div className="mobile-menu-items mb2">
                        {
                            nav.map((item, i) => (
                                <h4 key={item.title} className="mobile-menu-item mobile-menu-item--color">
                                    <Link to={item.url} className="mobile-menu-item-link db posr" onClick={props.onClose}>
                                        {item.title}
                                    </Link>
                                    <div className="mobile-menu-item-image"
                                    style={{backgroundImage: "url('"+ item.image +"')"}}
                                    />
                                </h4>
                            ))
                        }
                        
                        </div>
                        <div className="df aic mb1 posr color-gold">
                            <SocialIcons settings={props.settings}></SocialIcons>
                            <LanguageSwitcher activeLanguage={props.lang || "fi"} languages={props.languages} slug={props.slug}></LanguageSwitcher>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    )
}


export default MobileMenu