import React, { useEffect } from "react"
import {
  FiPhoneCall,
  FiInstagram,
  FiFacebook,
  FiMapPin,
//   FiMenu,
} from "react-icons/fi"
import { FaPinterestP } from "react-icons/fa"
// import { TiSocialPinterest } from 'react-icons/ti';
import "./info-menu.css"
import LanguageSwitcher from "../languageSwitcher"
import slugHandler from "../../api/slugHandlerBrowser"
// import { navigate } from "gatsby"
import { loadUserLanguage, saveUserLanguage } from "../../api/userLanguage"
// import StyleSettings from '../style-settings/style-settings';

export default function InfoMenu(props) {
  // let [visible, setVisible] = useState(true);

  useEffect(() => {
    let menuTriggerVisible = false
    let infoMenuDiv = document.querySelector(".info-menu")
    let topMenuDiv = document.querySelector(".top-menu")

    function detectLanguage(activeLanguage, languages, slug){
      if (!navigator.language) return;
      const navLang = navigator.language.split("-")[0];
      const userLang = loadUserLanguage();
      if (userLang && userLang !== activeLanguage && languages.includes(userLang)){
        // console.log("Found saved language: "+userLang, ", switching!");
        saveUserLanguage(userLang);
        window.location.href = slugHandler(userLang, slug);
        // navigate(slugHandler(userLang, slug));
      }
      else if (!userLang && navLang && navLang !== activeLanguage && languages.includes(navLang)) {
        // console.log("Browser language detected: "+navLang, ", switching!");
        saveUserLanguage(userLang);
        window.location.href = slugHandler(navLang, slug);
        // navigate(slugHandler(navLang, slug));
      }
    }

    let menuHandleScroll = () => {
      let topMenuRect = topMenuDiv.getBoundingClientRect()
      // console.log(topMenuRect);

      if (topMenuRect.bottom < 0) {
        if (menuTriggerVisible) return
        infoMenuDiv.classList.add("info-menu--trigger")
        menuTriggerVisible = true
      } else {
        if (!menuTriggerVisible) return
        menuTriggerVisible = false
        infoMenuDiv.classList.remove("info-menu--trigger")
      }
    }
    window.addEventListener("scroll", menuHandleScroll);
    if (props.settings.detectLanguage) detectLanguage(props.lang, props.languages, props.slug);
    return () => {
      //   document.querySelector("body").style.overflow = "auto";
      window.removeEventListener("scroll", menuHandleScroll)
    }
  })

  return (
    <div className="info-menu">
      <div className="info-menu-in container-1200 aic">
        <div className="info-items df">
          <a
            href={props.settings.googlemaplink}
            target="_blank"
            rel="noreferrer"
            className="info-item"
          >
            <span className="mr05">
              <FiMapPin className="icon"></FiMapPin>
            </span>
            <span className="info-item__addr1">{props.settings.address}</span>
            <span className="info-item__addr2">
              , {props.settings.address2}
            </span>
          </a>
          <a
            href={"tel:" + props.settings.phone}
            target="_blank"
            rel="noreferrer"
            className="info-item"
          >
            <span className="mr05">
              <FiPhoneCall className="icon"></FiPhoneCall>
            </span>
            <span>{props.settings.phone}</span>
          </a>
        </div>
        <div className="fg1"></div>
        <div className="info-items df aic">
          {/* <span target="_blank" rel="noreferrer" className="info-item mr2">
                    <StyleSettings></StyleSettings>
                </span> */}
          <a
            href={props.settings.instagram}
            target="_blank"
            aria-label="instagram"
            rel="noreferrer"
            className="info-item info-item--social tablet"
          >
            <FiInstagram className="icon"></FiInstagram>
          </a>
          <a
            href={props.settings.facebook}
            target="_blank"
            aria-label="facebook"
            rel="noreferrer"
            className="info-item info-item--social tablet"
          >
            <FiFacebook className="icon"></FiFacebook>
          </a>
          <a
            href={props.settings.pinterest}
            target="_blank"
            rel="noreferrer"
            aria-label="pinterest"
            className="info-item info-item--social tablet"
          >
            <FaPinterestP className="icon"></FaPinterestP>
          </a>
          <button
            className={
              "info-item tablet-to info-item--trigger" +
              (props.mobileMenuVisible ? " top-menu-trigger--open" : "")
            }
            onClick={() => props.setMobileMenuVisible(true)}
          >
            MENU
            {/* <FiMenu className="icon"></FiMenu> */}
          </button>
          <LanguageSwitcher
            activeLanguage={props.lang || "fi"}
            languages={props.languages}
            slug={props.slug}
            className="langSwitch--bg info-item info-item--lang "
          ></LanguageSwitcher>
        </div>
      </div>
    </div>
  )
}
